import React from 'react';

import { Icon } from '@hero-design/react';

const ChatIcon = () => (
  <Icon
    size="medium"
    icon="chat-outlined"
    data-test-id="chat-icon"
    intent="primary"
    sx={{
      p: 'xsmall',
    }}
  />
);

export default ChatIcon;
