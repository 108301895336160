import isEmpty from 'lodash/fp/isEmpty';

import React, { useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Typography } from '@hero-design/react';

import {
  AppliedJobItem,
  JobItem,
  SavedJobItem,
  SearchCareerPageJobItem,
} from '../../types';

type CompanyNameProps = {
  data: JobItem | SearchCareerPageJobItem | SavedJobItem | AppliedJobItem;
  companyName?: string;
  isOrgNewUI?: boolean;
  disableLink?: boolean;
};

const CompanyName = ({
  data,
  companyName,
  isOrgNewUI,
  disableLink,
}: CompanyNameProps) => {
  const router = useRouter();

  const { organisation_id: orgId, organisation_friendly_id: orgFriendlyId } =
    data;
  const jobAdvertId = 'job_advert_id' in data ? data.job_advert_id : undefined;

  const shouldShowCompanyFriendlyUrl = !!orgFriendlyId;
  const companyUrl = shouldShowCompanyFriendlyUrl
    ? `/organisations/${orgFriendlyId}`
    : `/jobs/organisations/${orgId}`;

  // Only show text if it's job advert or organisation or link is manually disabled
  const showCompanyName = useMemo(() => {
    const currentOrganisationId = shouldShowCompanyFriendlyUrl
      ? orgFriendlyId
      : orgId;
    const isOrganisationPage = router.query?.id === currentOrganisationId;

    return jobAdvertId || isOrganisationPage || disableLink;
  }, [
    shouldShowCompanyFriendlyUrl,
    orgFriendlyId,
    orgId,
    router.query?.id,
    jobAdvertId,
    disableLink,
  ]);

  if (isEmpty(companyName)) {
    return null;
  }

  return (
    <Typography.Text
      intent={isOrgNewUI ? undefined : 'body'}
      sx={{ mr: 'xsmall', lineHeight: 'xlarge' }}
      tagName="span"
    >
      {!isOrgNewUI && <>at&nbsp;</>}

      {showCompanyName ? (
        companyName
      ) : (
        <Link
          href={companyUrl}
          passHref
          style={{ position: 'relative', zIndex: 1 }}
        >
          {companyName}
        </Link>
      )}
    </Typography.Text>
  );
};

export default CompanyName;
