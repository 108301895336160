import {
  AppliedJobItem,
  JobItem,
  SavedJobItem,
  SearchCareerPageJobItem,
} from 'src/modules/CareersPage/types';

const getCareerPageJobId = (
  jobData: JobItem | SearchCareerPageJobItem | SavedJobItem | AppliedJobItem,
  permissions: {
    swagSeoStrategy2024Enabled: boolean;
  }
): string => {
  const { id } = jobData;
  const friendlyId = 'friendly_id' in jobData ? jobData.friendly_id : undefined;
  const jobId = 'job_id' in jobData ? jobData.job_id : undefined;
  const careerPageJobId =
    'career_page_job_id' in jobData ? jobData.career_page_job_id : undefined;
  const careerPageJobFriendlyId =
    'career_page_job_friendly_id' in jobData
      ? jobData.career_page_job_friendly_id
      : undefined;

  const { swagSeoStrategy2024Enabled } = permissions;

  if (swagSeoStrategy2024Enabled && friendlyId) {
    return friendlyId;
  }

  if (swagSeoStrategy2024Enabled && careerPageJobFriendlyId) {
    return careerPageJobFriendlyId;
  }

  return careerPageJobId || jobId || id || '';
};

export default getCareerPageJobId;
