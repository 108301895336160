import { useMemo } from 'react';
import { useRouter } from 'next/router';
import type { SWRConfiguration } from 'swr';
import useSWR from 'swr';

import { useFetch } from '@packages/eh-utils/api';
import { getAtsServiceDirectApiHost } from '@packages/eh-utils/browserEnv';

import {
  CountriesList,
  DataWrapper,
  ErrorData,
  InitFetchData,
} from '../types/index';

const useFetchCountriesList = (
  configs: SWRConfiguration = {},
  initCountries?: InitFetchData<CountriesList>
) => {
  const router = useRouter();

  const { fetcher } = useFetch<DataWrapper<CountriesList>, { mode: string }>({
    method: 'GET',
    endpoint: `${getAtsServiceDirectApiHost()}/api/v1/career_page/countries`,
    withSWR: true,
  });

  const shouldFetchOnMount = !initCountries || !!initCountries?.errorCode;
  const initFetchData = initCountries?.data;
  // normalize to match with data shape of fetch
  const initCountriesData = useMemo(
    () => initFetchData && { data: initFetchData },
    [initFetchData]
  );

  const finalQuery = useMemo(
    () => ({
      mode: router?.query?.mode,
    }),
    [router?.query?.mode]
  );

  const {
    data,
    mutate: fetchCountriesData,
    error: countriesError,
  } = useSWR<DataWrapper<CountriesList>, ErrorData>(
    { key: 'career_page_countries', params: finalQuery },
    ({ params }) => fetcher(params),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: shouldFetchOnMount,
      ...configs,
    }
  );

  return {
    rawCountriesData: data || initCountriesData,
    fetchCountriesData,
    countriesError,
    isLoadingCountries: !initCountriesData && !data && !countriesError,
  };
};

export default useFetchCountriesList;
