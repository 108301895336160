import { ApiConfigs, useFetch } from '@packages/eh-utils/api';
import { getAtsServiceDirectApiHost } from '@packages/eh-utils/browserEnv';

export type TUnsaveJobParams = {
  jobId: string;
};

type TUnsaveJobProps = Pick<
  ApiConfigs<unknown, TUnsaveJobParams>,
  'onCompleted'
>;

const useUnsaveJob = ({ onCompleted }: TUnsaveJobProps) => {
  const { fetcher, loading } = useFetch<unknown, TUnsaveJobParams>({
    method: 'DELETE',
    endpoint: `${getAtsServiceDirectApiHost()}/api/v1/job_seeker/jobs/:jobId/saved_jobs`,
    formatEndpoint: ({ endpoint, params }) =>
      params ? endpoint.toString().replace(':jobId', params.jobId) : '',
    onCompleted,
  });

  return {
    fetcher,
    loading,
  };
};

export default useUnsaveJob;
