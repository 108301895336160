import React, { useCallback } from 'react';

import { Button, Modal } from '@hero-design/react';

import {
  TRACK_ATS_WITHDRAW_APPLICATION,
  useMixpanelTracking,
} from '@shared/trackTools/mixpanel';

import useFetchWithdrawApplication from '../../hooks/useFetchWithDrawApplication';

import { APPLICATIONS_TRACKING_TABS } from '.';

type WithdrawApplicationModalProps = {
  id: string;
  setSelectedTab?: (value: string) => void;
  setIsConfirmWithdrawApplicationModal: (value: boolean) => void;
};

const WithdrawApplicationModal = ({
  id,
  setIsConfirmWithdrawApplicationModal,
  setSelectedTab,
}: WithdrawApplicationModalProps) => {
  const { fetchWithdrawApplication } = useFetchWithdrawApplication();
  const { track } = useMixpanelTracking();

  const closeConfirmWithdrawApplicationModal = useCallback(() => {
    setIsConfirmWithdrawApplicationModal(false);
  }, []);

  const onWithdrawApplication = useCallback(() => {
    track(TRACK_ATS_WITHDRAW_APPLICATION);
    closeConfirmWithdrawApplicationModal();
    fetchWithdrawApplication({ applicationId: id });
    setSelectedTab?.(APPLICATIONS_TRACKING_TABS.ARCHIVED);
  }, []);

  return (
    <Modal.PopUp
      title="Withdraw Application?"
      open
      variant="warning"
      body="Are you sure you want to withdraw this application? This cannot be undone."
      footer={
        <>
          <Button
            text="Cancel"
            variant="text"
            onClick={closeConfirmWithdrawApplicationModal}
          />

          <Button text="Confirm" onClick={onWithdrawApplication} />
        </>
      }
    />
  );
};

export default WithdrawApplicationModal;
