import React from 'react';
import { signIn } from 'next-auth/react';

import { Button, theme, Tooltip } from '@hero-design/react';

const UnSigninSaveJobButton = () => (
  <Tooltip
    target={
      <Button.Icon
        icon="star-outlined"
        sx={{ alignItems: 'center', color: theme.colors.palette.grey }}
        size="large"
        data-test-id="un-signin-save-job-btn"
        onClick={() => signIn()}
      />
    }
    content={'Save job'}
  />
);

export default UnSigninSaveJobButton;
