import { DEFAULT_LOCALE } from '@shared/utils/autoDetectLocale/constants';

const genJobDetailPageLink = ({
  isJobAdvert,
  jobAdvertId,
  careerPageJobId,
  locale,
}: {
  isJobAdvert: boolean;
  jobAdvertId: string;
  careerPageJobId: string;
  locale?: string;
}): string => {
  const localePath = locale && locale !== DEFAULT_LOCALE ? `/${locale}` : '';

  return isJobAdvert
    ? `${localePath}/application_jobs/${jobAdvertId}`
    : `${localePath}/job/${careerPageJobId}`;
};

export default genJobDetailPageLink;
