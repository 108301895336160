import { Tag } from '@hero-design/react';

import { format } from '@shared/utils/formatDate';

const AppliedIndicator = ({ appliedAt }: { appliedAt: string }) => (
  <Tag
    text={`Applied ${format('DD MMM YYYY')(appliedAt)}`}
    variant="outlined"
    intent="success"
    style={{ fontSize: '14px' }}
    sx={{ mb: 'small' }}
  />
);

export default AppliedIndicator;
