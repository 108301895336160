import { useState } from 'react';

import { Box, Button, Icon, Modal, Typography } from '@hero-design/react';

import { addNotification } from '@shared/utils';

import { CandidateRecordingVideo } from 'src/modules/smartMatchCandidateIntro';

import { DefaultIntroductionVideoBenefits } from './IntroductionVideoBenefits';

const UploadVideoIntroduction = ({
  onRecordButtonClicked,
  onUseVideo,
  onUploadSuccess,
  title = 'Stand out from the crowd!',
  subtitle = 'Record a video introduction to increase your chances of being seen!',
  IntroductionVideoBenefits = DefaultIntroductionVideoBenefits,
}: {
  onRecordButtonClicked?: () => void;
  onUseVideo?: (blob: Blob) => void;
  onUploadSuccess?: () => void;
  title?: string;
  subtitle?: string | null;
  IntroductionVideoBenefits?: React.ComponentType;
}) => {
  const [openModal, setOpenModal] = useState(false);

  const handleRecordVideo = () => {
    onRecordButtonClicked?.();
    setOpenModal(true);
  };

  return (
    <>
      <Box tagName="span" sx={{ alignItems: 'center', display: 'flex' }}>
        <Icon icon="video-1" size="xlarge" sx={{ mr: 'small' }} />
        <Typography.Title level={5}>{title}</Typography.Title>
      </Box>
      {subtitle && (
        <Typography.Text intent="subdued" sx={{ mt: 'small', mb: 'medium' }}>
          {subtitle}
        </Typography.Text>
      )}
      <IntroductionVideoBenefits />
      <Button
        text="Record your video now"
        sx={{ mt: 'small' }}
        onClick={handleRecordVideo}
      />
      {openModal && (
        <Modal
          open
          title="Upload video introduction"
          onClose={() => setOpenModal(false)}
          size="xxlarge"
          body={
            <CandidateRecordingVideo
              onUploadSuccess={() => {
                onUploadSuccess?.();
                setOpenModal(false);
                addNotification({
                  intent: 'success',
                  content:
                    'Your video introduction has been uploaded successfully!',
                  title: 'Success',
                });
              }}
              sx={{ width: '100%' }}
              onUseVideo={onUseVideo}
            />
          }
        />
      )}
    </>
  );
};

export default UploadVideoIntroduction;
