import Image from '@packages/eh-adapter/image';

const QRCode = () => (
  <Image
    src="/assets/images/qrcode-mobile.png"
    alt="SWAG QR Code"
    width={240}
    height={240}
    sx={{ height: 'fit-content' }}
  />
);

export default QRCode;
