import React from 'react';

import useFetchCountriesList from '../../hooks/useFetchCountriesList';
import getLocationName from '../../utils/getLocationName';
import {
  AppliedJobItem,
  JobItem,
  SavedJobItem,
  SearchCareerPageJobItem,
} from '../../types';

import RoleItem from './RoleItem';

type RolesListProps = {
  data:
    | (JobItem | SearchCareerPageJobItem)[]
    | SavedJobItem[]
    | AppliedJobItem[];
  companyLogo?: string;
  companyName?: string;
  setSelectedTab?: (value: string) => void;
  selectedTab?: string;
  showStar?: boolean;
  fetchSavedJobs?: () => void;
};

const RolesList = ({
  data,
  companyLogo,
  companyName,
  setSelectedTab,
  selectedTab,
  showStar = false,
  fetchSavedJobs,
}: RolesListProps) => {
  const { rawCountriesData } = useFetchCountriesList();

  return (
    <>
      {data.map(item => (
        <RoleItem
          showStar={showStar}
          key={item.id}
          data={item}
          countryName={getLocationName(item.country_code, rawCountriesData)}
          companyLogo={companyLogo || item?.recruitment_logo?.logo_url || ''}
          companyName={companyName || item?.organisation_name || ''}
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
          fetchSavedJobs={fetchSavedJobs}
        />
      ))}
    </>
  );
};

export default RolesList;
