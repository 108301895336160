import dayjs from 'dayjs';
import isTodayPlugin from 'dayjs/plugin/isToday';

import convert from '@packages/time/convert';

import { DateType } from 'src/modules/CareersPage/components/JobDetails/types';

dayjs.extend(isTodayPlugin);

export const format =
  (formatType = '') =>
  (date: string, customFormat = '') => {
    const convertedDate = convert(date, customFormat);

    return convertedDate && dayjs(convertedDate).format(formatType);
  };

const DATE_FORMAT = 'DD-MM-YYYY';

export const formatDate = (value: string, customFormat = '') =>
  format(DATE_FORMAT)(value, customFormat) || '';

const TIME_FORMAT = 'HH:mm';

export const formatTime = (value: string, customFormat = '') =>
  format(TIME_FORMAT)(value, customFormat) || '';

const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm';

export const formatDatetime = (value: string, customFormat = '') =>
  format(DATETIME_FORMAT)(value, customFormat) || '';

export const isToday = (date: string, customFormat = '') => {
  const convertedDate = convert(date, customFormat);

  return convertedDate && dayjs(convertedDate).isToday();
};

const DEFAULT_DAY = '01';

export const formatInitFormDate = (date: DateType) => {
  const startMonth = date?.start_month;
  const endMonth = date?.end_month;
  const startYear = date?.start_year;
  const endYear = date?.end_year;
  const startDay = date?.start_day ?? DEFAULT_DAY;
  const endDay = date?.end_day ?? DEFAULT_DAY;

  return {
    start_date:
      startMonth && startYear
        ? `${startYear}-${startMonth}-${startDay}`
        : undefined,
    end_date:
      endMonth && endYear ? `${endYear}-${endMonth}-${endDay}` : undefined,
  };
};

export const formatCustomDate = (value: string, customFormat = 'DD/MM/YYYY') =>
  format(customFormat)(value) || '';
