import { useFetch } from '@packages/eh-utils/api';
import { getAtsServiceDirectApiHost } from '@packages/eh-utils/browserEnv';

import { ExtendedErrorData, JobsList } from '../types';

type JobsListResponse = {
  data: JobsList;
};

type TUseFetchWithdrawApplicationParams = {
  applicationId: string;
};

const useFetchWithdrawApplication = () => {
  const { fetcher, data, error, loading } = useFetch<
    JobsListResponse,
    TUseFetchWithdrawApplicationParams,
    ExtendedErrorData
  >({
    method: 'POST',
    endpoint: `${getAtsServiceDirectApiHost()}/api/v1/user/applied_jobs/:applicationId/withdraw`,
    formatEndpoint: ({ endpoint, params }) =>
      params?.applicationId
        ? endpoint.toString().replace(':applicationId', params.applicationId)
        : '',
  });

  return {
    withdrawData: data,
    withdrawError: error,
    fetchWithdrawApplication: fetcher,
    isFetchingWithdrawApplication: loading,
  };
};

export default useFetchWithdrawApplication;
