import { ApiConfigs, useFetch } from '@packages/eh-utils/api';
import { getAtsServiceDirectApiHost } from '@packages/eh-utils/browserEnv';

export type TSaveJobParams = {
  jobId: string;
};

const useSaveJob = ({
  onCompleted,
}: Pick<ApiConfigs<unknown, TSaveJobParams>, 'onCompleted'>) => {
  const { fetcher, data, loading } = useFetch<unknown, TSaveJobParams>({
    method: 'POST',
    endpoint: `${getAtsServiceDirectApiHost()}/api/v1/job_seeker/jobs/:jobId/saved_jobs`,
    formatEndpoint: ({ endpoint, params }) =>
      params ? endpoint.toString().replace(':jobId', params.jobId) : '',
    onCompleted,
  });

  return {
    loading,
    fetcher,
    data,
  };
};

export default useSaveJob;
