import isEmpty from 'lodash/fp/isEmpty';
import isNumber from 'lodash/fp/isNumber';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

const UNIX_TIME_STAMP = 'x';

dayjs.extend(customParseFormat);

const convert = (date: string, format = '', strict?: boolean) => {
  const useDefaultFormat = isEmpty(format);
  const isUnixTimeStamp = isNumber(date);

  if (isUnixTimeStamp) {
    return dayjs(date, UNIX_TIME_STAMP, strict);
  }

  const day = useDefaultFormat ? dayjs(date) : dayjs(date, format, strict);

  if (!day.isValid()) return undefined;

  return useDefaultFormat ? day.toISOString() : day;
};

export default convert;
