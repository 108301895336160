import useFetchUserProfile from '../../User/hooks/useFetchUserProfile';

const useEnhancedFetchUserProfile = () =>
  useFetchUserProfile(
    undefined,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
    {
      histories_sort: 'employment',
    }
  );

export default useEnhancedFetchUserProfile;
