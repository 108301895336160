import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import isNil from 'lodash/fp/isNil';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Pagination, Spinner, Tabs, Typography } from '@hero-design/react';

import { ContentWrapper } from '@shared/PageLayout';
import useFetchPermissions from '@shared/hooks/useFetchPermissions';
import {
  TRACK_ATS_VIEW_ARCHIVED_APPLICATIONS,
  TRACK_ATS_VIEW_IN_PROGRESS_APPLICATIONS,
  TRACK_ATS_VISIT_APPLICATION_PAGE,
  useMixpanelTracking,
} from '@shared/trackTools/mixpanel';

import RecordVideoIntroSection from 'src/modules/smartMatchCandidateIntro/CandidateVideoIntroduction/components/RecordVideoIntroSection';
import { TRACK_SMART_MATCH_VIDEO_INTRODUCTION } from 'src/modules/smartMatchCandidateIntro/tracks/events';
import useEnhancedFetchUserProfile from 'src/modules/userProfile/hooks/useEnhancedFetchUserProfile';
import { DEFAULT_ITEM_PER_PAGE } from 'src/constants';
import useMergeState from 'src/shared/hooks/useMergedState';

import useFetchAppliedJobs from '../../hooks/useFetchAppliedJobs';
import { onFilterChangeSWR } from '../../utils/helpers';

import RolesList from '../shared/RoleList';

export const APPLICATIONS_TRACKING_TABS = {
  APPLIED: 'applied',
  IN_PROGRESS: 'in_progress',
  ARCHIVED: 'archived',
};

type BaseTabContentProps = {
  selectedTab: string;
  setSelectedTab: (value: string) => void;
};

const BaseTabContent = ({
  selectedTab,
  setSelectedTab,
}: BaseTabContentProps) => {
  const [currentSelectedPage, setCurrentSelectedPage] = useState(1);
  const [queryParams, setQueryParams] = useMergeState({
    item_per_page: DEFAULT_ITEM_PER_PAGE,
    page_index: 1,
    status: selectedTab,
  });
  const { jobsRawData, isFetchingJobs } = useFetchAppliedJobs(queryParams, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  });

  const dataReady = useMemo(() => !isNil(jobsRawData), [jobsRawData]);
  const hasNoJobs = useMemo(
    () => dataReady && flow(get('data.items'), isEmpty)(jobsRawData),
    [dataReady, jobsRawData]
  );
  const onChangePage = useCallback(
    (selectedPage: number) => {
      setCurrentSelectedPage(selectedPage);
      onFilterChangeSWR({
        setQueryParams,
        queryParams,
      })({ page_index: selectedPage });
    },
    [queryParams]
  );
  const jobsList = useMemo(() => jobsRawData?.data?.items || [], [jobsRawData]);

  return (
    <>
      {isFetchingJobs && <Spinner />}

      {hasNoJobs ? (
        'No results found'
      ) : (
        <>
          <RolesList
            data={jobsList}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
          />

          {(jobsRawData?.data?.total_items || 0) > DEFAULT_ITEM_PER_PAGE && (
            <Box
              data-test-id="jobs-list-pagination"
              mt="large"
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Pagination
                current={currentSelectedPage}
                total={jobsRawData?.data?.total_pages || 0}
                onChange={onChangePage}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

const ApplicationsTracking = () => {
  const { track } = useMixpanelTracking();
  const [selectedTab, setSelectedTab] = React.useState('applied');
  const { permissionsData } = useFetchPermissions();
  const { userProfileData, fetchUserProfile } = useEnhancedFetchUserProfile();

  const videoIntroRecordEnabled =
    permissionsData?.data
      .sm_candidate_introduction_video_adoption_swag_oct24_enabled;
  const videoId = userProfileData?.data?.video_introduction?.id;

  const tabs = useMemo(
    () => [
      {
        id: APPLICATIONS_TRACKING_TABS.APPLIED,
        title: 'Applied',
        panel: (
          <BaseTabContent
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        ),
      },
      {
        id: APPLICATIONS_TRACKING_TABS.IN_PROGRESS,
        title: 'In Progress',
        panel: (
          <BaseTabContent
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        ),
      },
      {
        id: APPLICATIONS_TRACKING_TABS.ARCHIVED,
        title: 'Archived',
        panel: (
          <BaseTabContent
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        ),
      },
    ],
    [selectedTab]
  );

  useEffect(() => {
    track(TRACK_ATS_VISIT_APPLICATION_PAGE);
  }, []);

  return (
    <ContentWrapper>
      <Typography.Title level={3} sx={{ mb: 'xlarge' }}>
        Applications
      </Typography.Title>
      <Tabs
        id="application-tracking-tabs"
        renderActiveTabPanelOnly
        tabs={tabs}
        selectedTabId={selectedTab}
        onChange={newTab => {
          if (typeof newTab === 'string') {
            switch (newTab) {
              case APPLICATIONS_TRACKING_TABS.IN_PROGRESS: {
                track(TRACK_ATS_VIEW_IN_PROGRESS_APPLICATIONS);
                break;
              }

              case APPLICATIONS_TRACKING_TABS.ARCHIVED: {
                track(TRACK_ATS_VIEW_ARCHIVED_APPLICATIONS);
                break;
              }

              default:
                break;
            }

            setSelectedTab(newTab);
          }
        }}
      />
      {videoIntroRecordEnabled && !videoId && (
        <Box mt="large" boxShadow="default">
          <RecordVideoIntroSection
            onUseVideo={() => {
              track(
                TRACK_SMART_MATCH_VIDEO_INTRODUCTION.CLICK_USE_THIS_VIDEO_VIA_SWAG_JOB_APPLICATION_PAGE
              );
            }}
            onUploadSuccess={() => {
              fetchUserProfile();
            }}
          />
        </Box>
      )}
    </ContentWrapper>
  );
};

export default ApplicationsTracking;
