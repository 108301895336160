import { useMemo } from 'react';
import { useRouter } from 'next/router';
import type { SWRConfiguration } from 'swr';
import useSWR from 'swr';

import { useFetch } from '@packages/eh-utils/api';
import { getAtsServiceDirectApiHost } from '@packages/eh-utils/browserEnv';

import { AppliedJobItem, PaginatedResponse, QueryParams } from '../types';

export type FetchAppliedJobsResponse = {
  data: PaginatedResponse<AppliedJobItem>;
};

const useFetchAppliedJobs = (
  queryParams: QueryParams = {},
  configs: SWRConfiguration = {}
) => {
  const router = useRouter();
  const { fetcher } = useFetch<FetchAppliedJobsResponse, QueryParams>({
    endpoint: `${getAtsServiceDirectApiHost()}/api/v1/user/applied_jobs`,
    method: 'GET',
    withSWR: true,
  });

  const finalQuery = useMemo(
    () => ({
      ...queryParams,
      mode: router?.query?.mode as string,
    }),
    [queryParams, router?.query?.mode]
  );

  const {
    data: jobsRawData,
    mutate: fetchJobs,
    error: jobsError,
  } = useSWR<FetchAppliedJobsResponse>(
    {
      key: 'userAppliedJobs',
      params: finalQuery,
    },
    ({ params }) => fetcher(params),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...configs,
    }
  );

  return {
    jobsRawData,
    fetchJobs,
    jobsError,
    isFetchingJobs: !jobsRawData && !jobsError,
  };
};

export default useFetchAppliedJobs;
