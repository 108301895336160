import { useTheme } from 'styled-components';

import { Box, Icon, Typography } from '@hero-design/react';

const IntroductionVideoBenefits = ({
  text,
}: {
  text: string | React.ReactElement;
}) => {
  const theme = useTheme();

  return (
    <Box
      tagName="span"
      sx={{ alignItems: 'center', display: 'flex', mt: 'small', mb: 'medium' }}
    >
      <Icon
        icon="checkmark"
        size="small"
        sx={{ mr: 'small', color: theme.colors.warning }}
      />
      <Typography.Text intent="subdued">{text}</Typography.Text>
    </Box>
  );
};

export const DefaultIntroductionVideoBenefits = () => (
  <>
    <IntroductionVideoBenefits
      text={
        <>
          Candidates with a video introduction are <b>4X</b> more likely to hear
          from hiring managers!
        </>
      }
    />
    <IntroductionVideoBenefits text="It’s quick and easy—just 2 minutes, and we'll guide you through every step." />
  </>
);

export const ApplicationFlowIntroductionVideoBenefits = () => (
  <>
    <IntroductionVideoBenefits
      text={
        <>
          Profiles with a video introduction are <b>4X</b> more likely to hear
          from hiring managers!
        </>
      }
    />
    <IntroductionVideoBenefits text="Record a general video introduction for your profile to boost your visibility across all job applications." />
    <IntroductionVideoBenefits text="It’s fast and simple—just 2 minutes, and we'll guide you through each step." />
  </>
);
