import React, { CSSProperties } from 'react';

import { Box } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';

import { BLUR_DATA_URL } from 'src/constants';

const DEFAULT_LOGO_SIZE = 80;

type CompanyLogoProps = {
  companyLogo?: string;
  companyName?: string;
  extraStyles?: CSSProperties;
  logoSize?: number;
};

const CompanyLogo = ({
  companyLogo,
  companyName,
  extraStyles,
  logoSize = DEFAULT_LOGO_SIZE,
}: CompanyLogoProps) => (
  <>
    {companyLogo ? (
      <Box
        sx={{
          width: logoSize,
          height: logoSize,
          borderRadius: 'medium',
          position: 'relative',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...extraStyles,
        }}
      >
        <Image
          data-test-id="org_logo_img"
          src={companyLogo}
          alt={companyName}
          layout="fill"
          objectFit="contain"
          quality={100}
          priority
          placeholder="blur"
          blurDataURL={BLUR_DATA_URL}
        />
      </Box>
    ) : (
      <Box
        sx={{
          height: logoSize,
          width: logoSize,
          ...extraStyles,
        }}
      />
    )}
  </>
);

export default CompanyLogo;
