import { useFetch } from '@packages/eh-utils/api';
import { ApiConfigs } from '@packages/eh-utils/api/types';
import { getAtsServiceDirectApiHost } from '@packages/eh-utils/browserEnv';

import { TThread } from '../types';

type TCreateOrGetMessageThreadParams = {
  message_threadable_id: string;
  message_threadable_type: string;
};

type TCreateOrGetMessageThreadResponse = {
  data: {
    thread_document_id: string;
    status: TThread['status'];
  };
};

type TCreateOrGetMessageThreadProps = Pick<
  ApiConfigs<
    TCreateOrGetMessageThreadResponse,
    TCreateOrGetMessageThreadParams
  >,
  'onCompleted' | 'onFailed'
>;

const useCreateOrGetMessageThread = ({
  onCompleted,
  onFailed,
}: TCreateOrGetMessageThreadProps = {}) => {
  const { fetcher, loading } = useFetch<
    TCreateOrGetMessageThreadResponse,
    TCreateOrGetMessageThreadParams
  >({
    endpoint: `${getAtsServiceDirectApiHost()}/api/v2/firebase/message_threads`,
    method: 'POST',
    onCompleted,
    onFailed,
  });

  return {
    fetcher,
    loading,
  };
};

export default useCreateOrGetMessageThread;
